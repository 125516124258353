.latest-post {
    display: flex;
    flex-direction: column;
}

.latest-post .first-column {
    width: 100%
}

.latest-post .first-column img {
    width: 100%
}

.read-more {
    text-decoration: none;
    color: black;
}


.subtitle .date {
    display: flex;
    align-items: center;
    color: #a4a4a4;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 7px;
}


@media screen and (min-width: 700px) {
    .latest-post {
        flex-direction: row;
        margin-left: 15px;
        margin-right: 15px;
    }

    

    .latest-post .first-column {
        width: 50%
    }

    .latest-post .second-column {
        width: 50%;
        margin-left: 20px;
    }

    .latest-post .first-column img {
        width: 100%;
    }
}