.posts-container {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
}

.blog-post .post-title {
    text-decoration: none;
    color: black;
}