@font-face {
    font-family: 'Avenir-LT-Heavy';
    src: url('../static/fonts/Avenir-LT-85-Heavy.ttf');
}

@font-face {
    font-family: 'Avenir-LT-Light';
    src: url('../static/fonts/Avenir_LT_35_Light.ttf');
}

@font-face {
    font-family: 'Raleway';
    src: url('../static/fonts/Raleway/Raleway-Regular.ttf');
}

body {
    margin: 0;
    background-color: #FFFFFF;
    font-size: 18px;
    font-family: 'Avenir-LT-Light', Arial, Helvetica, sans-serif;
}
h2{
    font-size: 32px;
    font-family: 'Avenir-LT-Light', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}
html {
    color: #181818;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.pt-30 {
    padding-top: 30px;
}

.p-sides-20 {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (max-width: 400px) {
    .button {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}


body::-webkit-scrollbar {
    width: 10px;
}
   
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}