.full-width-center {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-container {
  min-height: 444px;
  background-image: url("../static/images/background-new.png");
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  opacity: 0.95;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .overview-container {
    background-image: url("../static/images/background-small.png");
    background-position: 100%;
  }
}

@media screen and (max-width: 530px) {
  .overview-container {
    background-image: url("../static/images/background-xs.png");
    background-position: 100%;
  }
}

.overview-text-container-container {
  width: 1000px;
}

.overview-text-container {
  width: 360px;
  margin-left: 12px;
}

.overview-text-title {
  font-size: 30px;
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 36px;
  color: white;
  padding-bottom: 15px;
}

.overview-text-line {
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.section-title {
  font-size: 30px;
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
  opacity: 0.9;
  margin-top: 70px;
  margin-bottom: 40px;
}

.team-container {
  width: 1000px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.member-pair {
  width: 500px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member-container {
  width: 206px;
  height: 590px;
}

.member-photo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.role-container {
  height: 34px;
  margin-top: -21px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.role {
  background-color: rgba(84, 84, 84, 0.9);
  height: 100%;
  width: 70%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.role:hover {
  background-color: rgba(242, 191, 94, 1);
  transition: ease 0.5s;
}

.role-text {
  opacity: 1;
  text-align: center;
  text-transform: uppercase;
}

.member-description {
  width: 100%;
  background-color: white;
  margin-top: -17px;
  margin-bottom: 40px;
}

.member-name {
  font-size: 22px;
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.member-description-text {
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #545454;
  opacity: 0.9;
  letter-spacing: -0.75px;
  padding-left: 5px;
  padding-right: 5px;
}

.trivia div {
  text-align: center;
  line-height: 0.7;
  padding-top: 25px;
  text-transform: uppercase;
}

.trivia div span {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 700;
}

.trivia div span:first-child {
  color: #bd4913;
}

.brands-container {
  width: 1000px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 35px;
}

.brand-container {
  height: 420px;
  border: solid #ccc 1px;
  width: 32%;
  min-width: 300px;
  background-color: white;
  padding: 20px 20px;
  box-sizing: border-box;
}

.partnership-container {
  height: 370px;
}

.brand-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.brand-logo {
  height: auto;
  width: 170x;
  max-height: 100px;
  max-width: 190px;
}

.brand-name-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-name {
  font-size: 20px;
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  width: 80%;
  color: #000000;
}

.brand-description-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.brand-description {
  font-size: 15px;
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 24px;
  text-align: justify;
  color: #000000;
  padding-bottom: 15px;
}

@media screen and (max-width: 800px) {
  .overview-text-container-container {
    width: auto;
  }

  .overview-text-container {
    width: 90%;
  }

  .overview-text-title {
    font-size: 25px;
    font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  }

  .brand-container {
    margin-bottom: 10px;
  }

  .brand-container:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 450px) {
  .member-pair {
    width: auto;
  }

  .team-member-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .stats {
    height: fit-content !important;
    padding-bottom: 80px;
  }

  .team-member-container {
    width: 300px;
  }

  .role {
    width: 50%;
  }
}
