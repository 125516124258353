.app {
}


p {
    line-height: 1.3;
    font-size: 18px;
    color: #181818;
}

