.header {
    background-color: white;
    display:flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}


@media screen and  (max-width: 1040px) {  
    .header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-width {
    width: 100vw;
    max-width: 100%;
}

.logo{
    height:50px;
}

.title {
    font-size: 15px;
    font-weight: 400;
    font-family: Raleway, Arial, Helvetica, sans-serif;
    text-align: center;
}

.nav-bar {
    display: flex;
    height: 40px;
    margin-top: 10px;
}

.nav-item {
    display: inline-block;
    font-size: 18px;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    color: black;
}

.nav-item:hover{
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.9;
}

#burger {
    display: none;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 180px;
    left: 0;
    right: 0;
    z-index: 2000;
}


@media screen and (max-width: 768px) {
    .logo{
        height:40px;
    }

    #burger {
        display: block;
    }

    .menu {
        transition: all ease-in-out .5s;
    }

    .menu a {
        width: 100%;
        text-align: center;
        padding-left: 0px;
    }

    .nav-collapse {
        display: none;
        height: 0;
    }

    .nav-expand {
        height: auto;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 59px;
        left: 0;
        background-color: white;
        z-index: 10000;
        width: 100%;
    }

}