h3 {
    line-height: 1.3;
}

li {
    line-height: 1.4;
}

.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.post-container .post-area {
    max-width: 800px;
    width: 100%;
    margin-bottom: 200px;
}

.post-container .post-area .featured-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.post-container .post-area .featured-image-container .featured-image {
    max-width: 100%;
    align-self: flex-start;
}

.subtitle {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.subtitle .category {
    line-height: 1.5;
    height: 30px;
    background-color: black;
    border-radius: 50px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 30px;
    padding-top: 6px;
}

.subtitle .date {
    margin-right: 30px;
    line-height: 1.5;
}

.subtitle .share {
    display: contents;
}
 

/* Media and text */

/* Media on the left */
.post-container .post-area .post .wp-block-media-text {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.post-container .post-area .post .wp-block-media-text figure {
    width: 100%;
    height: auto;
    margin: 0;
}
.post-container .post-area .post .wp-block-media-text img {
    width: 100%;
    height: auto;
}

.post-container .post-area .post .wp-block-media-text .wp-block-media-text__content {
    width: 100%;
}


@media screen and (min-width: 700px) {
    .post-container .post-area .featured-image-container .featured-image {
        max-width: 100%;
        min-height: 300px;
    }

    .post-container .post-area .post .wp-block-media-text {
        margin-top: 30px;
        flex-direction: row;
    }

    .post-container .post-area .post .wp-block-media-text .wp-block-media-text__content p:first-of-type {
        margin-top: 0px;
    }

    .post-container .post-area .post .wp-block-media-text figure {
        width: 100%;
        height: auto;
        margin: 0;
        margin-right: 20px;
    }

    .post-container .post-area .post .wp-block-media-text img {
        width: 100%;
        height: auto;
    }
    
    .post-container .post-area .post .wp-block-media-text .wp-block-media-text__content {
        width: 100%;
    }
}


/* Media on the right */
.post-container .post-area .post .has-media-on-the-right {
    color: green;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 700px) {
    .post-container .post-area .post .has-media-on-the-right {
        flex-direction: row-reverse;
    }

    .post-container .post-area .post .has-media-on-the-right figure {
        margin-right: 0px;
        margin-left: 20px;
    }

}