/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  display: flex;
  position: relative;
  align-items: center;
  background-image: url("../static/images/background-new.png");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  width: 100%;
  height: 50vh;
}

.main-title {
  font-size: 50px;
  color: white;
  width: 50%;
  text-shadow: 1px 1px black;
}

.words {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  z-index: 9000;
}

.words span {
  font-size: 50px;
  color: white;
  line-height: 1.2;
  font-weight: bold;
}

@media screen and (max-width: 1040px) {
  .words {
    padding-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .words span {
    font-size: 40px;
  }
}

.words .blk {
  color: #181818;
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
}

.words .about {
  font-weight: 500;
  border: 1px solid #6b6b6b;
}

.words h1 {
  line-height: 1.2;
}

.hero-link {
  color: white;
  width: fit-content;
}

.main-title .blk {
  color: #181818;
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: 5px;
  padding-right: 5px;
}

.text-block-title {
  width: 100%;
  margin-top: 50px;
}

.text-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  box-sizing: border-box;
}

/* Partners section */

.partners-section {
  width: 100%;
  background-color: #f4f4f5;
  padding: 40px 25px;
  box-sizing: border-box;
}

.partners-section .partners-header {
  justify-content: center;
  display: flex;
}

.partners-section .partners-container {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 1040px;
  flex-wrap: wrap;
}

.partners-section .partner {
  justify-content: center;
  display: flex;
  flex: 1;
}

.partners-section .partner img {
  width: 130px;
  height: 130px;
}

@media screen and (max-width: 1040px) {
  .partners-section,
  .partners-container {
    width: 100% !important;
  }
}

/* product-overview section */

.product-overview-section {
  max-width: 1000px;
}

.product-overview-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  flex-wrap: wrap;
}

.product-overview-section .product-overview-header {
  padding-top: 50px;
}

.product-overview-container .overview-text {
  width: 42%;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-overview-container .overview-image img {
  width: 100%;
}

.product-overview-container .overview-image {
  width: 45%;
}

@media screen and (max-width: 1040px) {
  .product-overview-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .product-overview-container {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .product-overview-container {
    flex-direction: column-reverse;
  }

  .product-overview-container .overview-image {
    width: 100%;
  }

  .product-overview-container .overview-text {
    width: 100%;
    padding-top: 30px;
  }
}

/* Horse health matters */

.horse-health-section {
  max-width: 1000px;
}

.horse-health-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 1040px) {
  .horse-health-section {
    width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

/* Video section */

.video-section {
  display: flex;
  width: 1000px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
  padding-top: 80px;
}

.video-section .video-header {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
}

.video-section .video-header h2 {
  padding-left: 20px;
  padding-right: 20px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 900px;
  border: 1px solid gray;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1040px) {
  .video-section,
  .video-container {
    width: 100% !important;
  }

  .video-container {
    border: 0;
  }
}

.tech-advantages .text-box {
  border-bottom: 1px #787d90 solid;
}

.tech-advantages .text-box .header-text {
  display: flex;
  flex-direction: column;
  width: 70%;
}

@media screen and (max-width: 770px) {
  .tech-advantages .text-box .header-text {
    width: 100% !important;
    align-items: center;
  }
}

.tech-advantages .text-box:last-child {
  border-bottom: none;
}

.tech-advantages .text-line {
  line-height: 1.6;
  padding: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
  width: unset;
}

.text-line {
  line-height: 1.6;
  padding: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
  width: 70%;
}

.inverted .text-line {
  text-align: right;
}

.paragraph-image {
  width: 25%;
}

.paragraph-image > img {
  width: 100%;
}

.inverted {
  flex-direction: row-reverse;
}

.hospital {
  padding-bottom: 25%;
  background-image: url("../static/images/horse-leg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
}

.horsey {
  padding-bottom: 25%;
  background-image: url("../static/images/horsey.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
}

.technology {
  padding-bottom: 25%;
  background-image: url("../static/images/technology.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
}

.tech-advantages {
  width: 100%;
  background-color: #f4f4f5;
  padding: 40px 25px;
  box-sizing: border-box;
}

.tech-advantages-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  width: 1000px;
  flex-wrap: wrap;
}

.tech-advantages-container .text-line {
  text-align: left;
}

.tech-advantages .paragraph-image {
  width: 200px;
}

.brochure {
  background-image: url("../static/images/1237-darker-smaller.jpg");
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1040px) {
  .tech-advantages-container {
    width: 100% !important;
  }
}

.features-container {
  display: flex;
  height: 400px;
}

.feature-container {
  width: calc(100% / 3);
  height: 100%;
}

.feature-title-container {
  height: 22%;
  font-size: 16px;
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-title {
  width: 50%;
  text-align: center;
  opacity: 0.9;
}

.feature-description-container {
  display: flex;
}

.feature-number {
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(180deg);
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-size: 25px;
  width: 15%;
  height: 230px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-description {
  width: 85%;
  text-align: justify;
  padding-right: 15px;
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.9;
}

@media screen and (max-width: 1040px) {
  .tech-advantages-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    background-image: url("../static/images/background-small.png");
    background-position: 100%;
  }

  .main-title .blk {
    padding-top: 5px;
  }

  .hospital {
    padding-bottom: 65%;
    background-image: url("../static/images/horse-leg-big.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }

  .horsey {
    padding-bottom: 65%;
    background-image: url("../static/images/horsey-big.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }

  .technology {
    padding-bottom: 65%;
    background-image: url("../static/images/technology-big.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }

  .tech-advantages-container {
    width: 100% !important;
  }

  .words {
    width: 50% !important;
    margin-left: initial !important;
  }

  .text-box {
    display: block;
  }

  .text-line {
    text-align: justify !important;
    width: 100% !important;
  }

  .text-line h3 {
    width: 100%;
    text-align: center;
  }

  .paragraph-image {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .tech-advantages-container .paragraph-image {
    width: 30% !important;
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  .hero-section {
    height: 90vh;
  }

  .brochure {
    height: 90vh;
  }

  .main-title {
    font-size: 22px;
  }

  .hospital {
    padding-bottom: 25%;
    background-image: url("../static/images/surgery.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }

  .horsey {
    padding-bottom: 25%;
    background-image: url("../static/images/horsey.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }

  .technology {
    padding-bottom: 25%;
    background-image: url("../static/images/technology.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
  }
  .text-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    box-sizing: border-box;
  }

  .paragraph-image {
    width: 25%;
  }

  .text-line {
    width: 70% !important;
  }
}

@media screen and (max-width: 500px) {
  .main-title {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 400px) {
  .main-title {
    font-size: 32px !important;
  }
}

.findings-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  line-height: 32px;
  height: 42px;
  margin-top: 20px;
  display: inline;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: black;
  color: white;
  transition: 0.2s ease;
  text-decoration: none;
  padding: 10px 40px;
  padding-bottom: 0;
  width: fit-content;
  box-sizing: border-box;
}

.button:hover {
  cursor: pointer;
  background-color: #333333;
}

.download-brochure-text {
  font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.horse-carousel {
  width: 100vw;
  max-width: 100%;
}

.horse-carousel-image {
  height: 25vw;
  min-height: 200px;
  max-height: 400px;
}

.horse1-image-text {
  font-family: Avenir-LT-Heavy, Arial, Helvetica, sans-serif;
  font-size: 44px;
  color: white;
  opacity: 1;
}

.carousel-status {
  display: none;
}

.thumbs-wrapper {
  display: none;
}

.who-we-are {
  background-color: #2a2c31;
  width: 100%;
}

/* Project needs section */

.project-needs-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  width: 1000px;
  flex-wrap: wrap;
}

.project-needs-container .header-area {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 20px;
  padding-right: 20px;
}

.project-needs-container .row {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #cccccc;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.project-needs-container .row:first-of-type {
  border-top: 0;
}

.project-needs-container .row .number {
  font-size: 70px;
  height: 160px;
  width: 200px;
  text-align: center;
  flex-direction: column;
  padding: 30px;
  padding-top: 40px;
  line-height: 1.2;
  box-sizing: border-box;
}

.project-needs-container .row .text {
  width: 80%;
}

.project-needs-container .row .text p {
  line-height: 1;
}

@media screen and (max-width: 800px) {
  .project-needs-container .row .text p {
    display: -webkit-inline-flex;
  }
}

.project-needs-container .row .text h3 {
  font-size: 32px;
  display: -webkit-flex;
  line-height: 1;
}

@media screen and (max-width: 1040px) {
  .project-needs-container {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 700px) {
  .project-needs-container .header-area {
    padding-left: 70px;
  }

  .project-needs-container .row {
    flex-direction: column;
    padding-right: 20px;
  }

  .project-needs-container .row .number {
    display: none;
  }
  .project-needs-container .row:last-child {
    border-bottom: 0;
  }
}

/* Who we are section */

.who-we-are .block-area {
  display: flex;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  width: 1040px;
  flex-wrap: wrap;
}
.who-we-are .block-area .block-row {
  display: flex;
  margin-bottom: 50px;
}

.who-we-are .block-area .block-row .block {
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid white;
  margin: 25px;
  padding: 25px;
}

.who-we-are .block-area .block h5 {
  color: #f4f4f5;
  font-size: 32px;
  margin: 0;
  display: -webkit-flex;
  line-height: 1.2;
}

.who-we-are .block-area .block p {
  color: #f4f4f5;
}

@media screen and (max-width: 1040px) {
  .who-we-are .block-area {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .who-we-are .block-area .block-row {
    flex-direction: column;
    margin-bottom: 0;
  }

  .who-we-are .block-area .block p {
    line-height: 1;
    display: -webkit-flex;
  }

  .who-we-are .block-area .block h5 {
    line-height: 1;
  }

  .who-we-are .block-area .block-row .block {
    flex: 100%;
  }
}
