.latest-post-container {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
}


@media screen and (min-width: 700px) {
    .latest-post-container {
        max-width: 1000px;
        margin-top: 50px;
    }
}


.body-container {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
}

.latest-blog-post {
    display: flex;
    margin: 10px;
}

.latest-blog-post .image-container {
    width: 50%;
}
.latest-blog-post .image-container img {
    width: 100%;
}

.latest-blog-post .text-container {
    width: 50%;
    margin-left: 30px;
}

.blog-post {
    margin: 10px;
    flex: 25%;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.blog-post img {
    width: 100%;
}

.blog-post h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-post .share {
    height: 30px;
    width: 30px;
}

.blog-post .share img {
    color: gray;
}

.blog-post .image-container {
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}


@media screen and (max-width: 900px) {
    .blog-post {
        flex: 40%;
    }
}

@media screen and (max-width: 550px) {
    .blog-post {
        flex: 50%;
    }
}

.blog-post .text-container {
    width: 100%;
}

.text-container .date {
   display: flex;
   align-items: center;
}

.text-container .date img {
    width: 20px;
    height: 20px;
}
.text-container .date span {
    color: #a4a4a4;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 7px;
}

.text-container .post p{
    margin-top: 0px;
}

.text-container .continue {
    text-decoration: underline;
    height: 45px;
    align-content: unset;
    font-size: 18px;
    display: flex;
    align-items: flex-end;
    color: #188ca5;
    cursor: pointer;
    position: relative;
    bottom: 20px;
    background: linear-gradient(to top, rgba(255,255,255,1) 65%,rgba(255,255,58,0) 100%);
}

.text-container .continue:hover {
    filter: brightness(50%);
    background: linear-gradient(to top, rgba(255,255,255,0) 65%,rgba(255,255,255,0) 100%);

}

.blog-post .text-container .post {
    transition: all ease-in-out .5s;
    line-height: 1.3;
}

.blog-post .text-container .excerpt {
    overflow: hidden;
    height: 150px;
}




.blog-post .text-container .read-more {
    line-height: 2;
    height: 42px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: black;
    color: white;
    transition: 0.2s ease;
    text-decoration: none;
    padding: 0px 40px;
    width: fit-content;
}

.blog-post .text-container .post a:hover {
    cursor: pointer;
    background-color: #333333;
}




@media screen and (max-width: 450px) {
    .blog-post {
        flex-direction: column;
    }
    .blog-post .image-container {
        width: 100%;
    }

    .blog-post .date {
        margin-top: 10px;
    }

    .blog-post .text-container {
        width: 100%;
        padding-left: 0px;
    }

    .blog-post .text-container .post p {
        text-align: justify;
    }

    .blog-post {
        position: relative;
    }

    .blog-post .share {
        position: absolute;
        right: 15px;
        bottom: 15px;
        height: 30px;
        width: 30px;
    }
}


@media (orientation: landscape) and (max-width: 768px) {
    .blog-post .image-container {
        padding-top: 70px;
    }
}