.footer {
    background-color: #2A2C31;
    color: #f4f4f5;
    font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
    padding: 30px 25px;
}

.footer-container {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}


.footer-container h3 {
    line-height: 1.2;
}

.contacts {
    width: 50%;
}


.contacts-line img {
    width: 20px;
    height: auto;
    padding-right: 10px;
}

.column {
    width: 50%;
}

.info {
    width: 100%;
}

.info p {
    color: #f4f4f5;
}



.media {
    width: 100%;
}

.media img {
    width: 35px;
    height: auto;
    padding-right: 15px;
}




.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.full-width-center {
    width: 100vw;
    max-width: 100%;
}

.contacts-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts-title {
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
}

.contacts-line {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contacts-line-text {
    word-wrap: none;
    line-height: 1.2;
}



.footer-text {
    font-family: Avenir-LT-Light, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-top: 1px solid #f4f4f5;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1040px) {
    .footer-container {
        width: 100% !important;
    }
}

@media screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }

    .footer-container .column {
        width: 100%;
    }

    .contacts {
        width: 100%;
        padding-bottom: 20px;
    }

    .info {
        width: 100%;
    }
}
